





















import Vue from 'vue';

export default Vue.extend({
  name: 'LangSwitch',
  data() {
    return { langs: ['de', 'en'] };
  },
  methods: {
    updateLocalLang(event: any) {
      localStorage.setItem('language', event.target.value);
      this.$i18n.locale = event.target.value;
    },
  },
});
