























































































import Vue from 'vue';
import Scaffold from '@/components/Presenter/Scaffold.vue';
import { mapGetters } from 'vuex';
import LangSwitch from '@/components/LangSwitch.vue';

export default Vue.extend({
  components: { Scaffold, LangSwitch },
  mounted() {
    this.email = this.user.data.email;
  },
  data() {
    return {
      email: '',
      active: false,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    }),
  },
  methods: {
    updateEmail(event: any) {
      this.email = event.target.value;
    },
    saveUpdatedEmail() {
      // TODO: Add password input
      // this.$store.dispatch('presenter/updateEmail',{oldEmail: this.user.data.email, newEmail:  this.email, password: ''})
    },
  },
});
